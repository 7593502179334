import * as React from "react"
import { Link } from "gatsby"
import Seo from "../components/Seo"
import Layout from "../components/Layout"

// ! Edit page title here so it updates the title as well as SEO !
const pageTitle = "Page not found"

const NotFoundPage = () => {
  return (
    <Layout>
      <h1>{pageTitle}</h1>
      <p>
        Sorry, we couldn't find what you were looking for.
        <br />
        {process.env.NODE_ENV === "development" ? (
          <>
            <br />
            Try creating a page in <code>src/pages/</code>.
            <br />
          </>
        ) : null}
        <br />
        <Link to="/">Go home</Link>.
      </p>
    </Layout>
  )
}

export default NotFoundPage

export const Head = () => <Seo title={pageTitle} />
